import { Modal } from 'bootstrap/js/index.esm.js';
import md5 from 'js-md5';

window.md5 = md5;

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.form-need-validate')) {
    document.querySelectorAll('.form-need-validate').forEach((form) => {
      form.addEventListener('input', (e) => {
        if (!e.target.checkValidity()) {
          e.target.classList.add('invalid');
        } else {
          e.target.classList.remove('invalid');
        }
      });
    });
  }

  if (document.querySelector('.btn-lang-toggle')) {
    document
      .querySelector('.btn-lang-toggle')
      .addEventListener('click', (e) => {
        e.target.closest('.choose-lang').classList.toggle('active');
      });
  }

  if (document.querySelector('[data-changelang]')) {
    window.siteLang = 'en';

    document.querySelectorAll('[data-changelang]').forEach((el) => {
      el.addEventListener('click', (e) => {
        document.querySelectorAll('[data-changelang]').forEach((element) => {
          element.classList.remove('active');
        });
        e.currentTarget.classList.add('active');
        document.querySelector('.btn-lang-toggle').click();

        if (window.siteLang === 'en') {
          window.siteLang = 'ru';
          changeAllLangToRu();
        } else {
          window.siteLang = 'en';
          changeAllLangToEn();
        }
      });
    });
  }

  setTimeout(() => {
    document.querySelectorAll('[data-sticky]').forEach((element) => {
      element.dataset.basetop = element.parentElement.offsetTop;
    });

    window.floatBtnEl = document.getElementById('float-btn');
    window.floatBtnEl.dataset.basetop =
      window.floatBtnEl.parentElement.offsetTop +
      window.floatBtnEl.parentElement.scrollHeight;

    window.addEventListener(
      'scroll',
      () => {
        checkStickyPosition();
        checkStickyBtn();
      },
      { passive: true },
    );
  }, 500);

  /* if (document.querySelector('.form-payment')) {
      const forms = document.querySelectorAll('.form-payment');

      forms.forEach((form) => {
        form.addEventListener('submit', submitPaymentHandler);
      });
    } */

  console.log('hehe9');
});

function checkStickyBtn() {
  if (
    window.scrollY + window.innerHeight >
    Number(window.floatBtnEl.dataset.basetop) + 70
  ) {
    window.floatBtnEl.classList.add('sticked');
  } else {
    window.floatBtnEl.classList.remove('sticked');
  }
}

function checkStickyPosition() {
  document.querySelectorAll('[data-sticky]').forEach((element) => {
    const newOffset = element.offsetTop;

    if (newOffset > element.dataset.basetop) {
      element.classList.add('sticked');
    } else {
      element.classList.remove('sticked');
    }
  });
}

function langInit() {
  if (document.querySelector('[data-langtrigger]')) {
    const langTriggerEl = document.querySelector('[data-langtrigger]');
    window.siteLang = langTriggerEl.dataset.langtrigger;

    langTriggerEl.addEventListener('click', changeLangHandler);
  }
}

function changeLangHandler(e) {
  e.preventDefault();

  if (window.siteLang === 'en') {
    window.siteLang = 'ru';

    changeAllLangToRu();
  } else {
    window.siteLang = 'en';
    changeAllLangToEn();
  }
}

function changeAllLangToRu() {
  document.querySelectorAll('[data-langen]').forEach((element) => {
    element.innerHTML = element.dataset.langru;
  });
  document.querySelectorAll('[data-inputlang]').forEach((input) => {
    input.value = 'ru';
  });
}
function changeAllLangToEn() {
  document.querySelectorAll('[data-langru]').forEach((element) => {
    element.innerHTML = element.dataset.langen;
  });
  document.querySelectorAll('[data-inputlang]').forEach((input) => {
    input.value = 'en';
  });
}

function submitPaymentHandler(e) {
  e.preventDefault();

  const formElement = e.currentTarget;
  const url = formElement.action;
  const method = formElement.method;
  const formData = new FormData(formElement);

  fetch(url, {
    method: method,
    body: formData,
  })
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
}
